import CustomSection from "components/CustomSection";
import { Container, Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";

import img1 from "assets/static/landing/carousel_img1.jpg";
import img2 from "assets/static/landing/carousel_img2.jpg";
import img3 from "assets/static/landing/carousel_img3.jpg";
import ScrollTransition from "components/ScrollTransition";

const Services = () => {
  const [ref, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  });

  useEffect(() => {
    console.log(inView);
  }, [inView]);

  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
        padding: "8rem 0",
      }}
      id="servicos"
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
        }}
      >
        <ScrollTransition>
          <Typography
            variant="h2"
            component="h2"
            ref={ref}
            sx={{
              color: "var(--blue-0)",
              textAlign: "center",
              fontWeight: "bold",
              marginBottom: "1.4rem",
            }}
          >
            Serviços
          </Typography>
        </ScrollTransition>

        <ScrollTransition>
          <Typography
            variant="p"
            component="p"
            sx={{
              // color: "var(--text-color-reverse-1)",
              color: "var(--text-color-1)",
              textAlign: "center",
              margin: "0 auto",
              marginBottom: "4rem",
              fontWeight: "normal",
              fontSize: "1.3rem",
              maxWidth: "30rem",
              "&:after": {
                content: "''",
                display: "block",
                marginTop: "30px",
                width: "40px",
                borderTop: "2px solid #444",
                margin: "30px auto 0",
              },
            }}
          >
            Nossos serviços
          </Typography>
        </ScrollTransition>

        {/* <ScrollTransition> */}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "4rem 2rem",
              justifyContent:'center'
            }}
          >
            <ServiceCard
              title="Licenciamento Ambiental"
              description="Licença Prévia, Licença de Instalação, Licença de Operação, Licenciamento Ambiental Simplificado e Renovação do Licenciamento Ambiental"
              icon={img1}
            />
            <ServiceCard
              title="Certificado de Dispensa de Licença"
              // description="Wafer cake wafer pie jelly donut pastry muffin. I love sesame snaps jelly-o cake sugar plum powder"
              icon={img2}
            />
            <ServiceCard
              title="Declaração de Atividade Isenta de Licenciamento"
              // description="Sugar plum chocolate cake shortbread pie cheesecake cake I love pastry chocolate. I love topping cheesecake chocolate cake I love ice cream chocolate cake danish. "
              icon={img3}
            />
            <ServiceCard
              title="Alterações de Documentos"
              // description="Sugar plum chocolate cake shortbread pie cheesecake cake I love pastry chocolate. I love topping cheesecake chocolate cake I love ice cream chocolate cake danish. "
              icon={img3}
            />
          </div>
        {/* </ScrollTransition> */}
      </Container>
    </CustomSection>
  );
};

const ServiceCard = ({ title, description, icon }) => {
  return (
    <Box
      sx={{
        // width: "24rem",
        maxWidth:400,
        width:400,
        // minWidth:400,

        // flexGrow: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "var(--background-1)",
        borderRadius: "0.8rem",
        position: "relative",
        boxShadow: "0 0 var(--background-1)",
        border: "1px solid var(--background-1)",
        transition: "all 0.3s",
        cursor: "pointer",
        overflow: "hidden",
        border: "1px solid rgba(0, 0, 0, 0.1)",
        "&:hover": {
          boxShadow: "rgba(17, 12, 46, 0.2) 0px 48px 100px 0px",
          // backgroundColor: "var(--background-0)",
          transform: "translateY(-1rem)",
        },
      }}
    >
      <img
        src={icon}
        style={{
          width: "100%",
          height: "20rem",
          objectFit: "cover",
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "3rem 2rem",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <h3
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "var(--text-color-1)",
            textAlign:'center'
          }}
        >
          {title}
        </h3>
        <p
          style={{
            fontSize: "1rem",
            fontWeight: "normal",
            color: "var(--text-color-1)",
            textAlign: "center",
          }}
        >
          {description}
        </p>

        {/* <button
          className={`Transition-1 ${styles.Button}`}
          style={{
            position: "relative",
          }}
        >
          Ver mais
          <img
            className="Transition-1"
            src={arrow_white}
            alt="Ver mais"
            style={{
              position: "absolute",
              top: "80%",
              left: "10%",
              transform: "translate(-50%, -50%)",
              opacity: "0",
            }}
          />
        </button> */}
      </div>
    </Box>
  );
};

export default Services;

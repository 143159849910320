import { Box, Container } from "@mui/material";
import CustomSection from "components/CustomSection";
import bg_landing from "assets/static/landing/bg_landing.jpg";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import ScrollTransition from "components/ScrollTransition";

const Landing = ({ on_top }) => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      id="home"
      bg_image={bg_landing}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          alignItems: "center",
          color: "var(--text-color-reverse-0)",
        }}
      >
        <Box
          sx={{
            width: {
              xs: "auto",
              sm: "auto",
              md: "60%",
              lg: "60%",
            },
            display: "flex",
            flexDirection: "column",
            gap: "3vh",
            borderLeft: "0.2rem solid var(--green-0)",
            paddingLeft: "2vw",
            paddingBottom: "0.8rem",
            borderRadius: "0.5rem",
          }}
        >
          <ScrollTransition>
            <h1
            class="font_h1"
              style={{
                fontSize: "4rem",
                textShadow: "0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5)",
              }}
            >
              Licenciamento ambiental
            </h1>
          </ScrollTransition>

          <ScrollTransition>
            <h2
            class="font_h2"

              style={{
                textShadow: "0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5)",
              }}
            >
             Trabalhamos em todas as fazes do processo para a obtenção da sua Licença Ambiental, prestando o suporte necessário do início ao final do licenciamento.
            </h2>
          </ScrollTransition>

          {/* <ScrollTransition>
            <h2
              // add shadow to text
              style={{
                textShadow: "0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5)",
              }}
            >
              Sugar plum chocolate cake shortbread pie cheesecake cake I love
              pastry.
            </h2>
          </ScrollTransition> */}

          {/* <ScrollTransition>
            <div
              style={{
                display: "flex",
                gap: "1rem",
              }}
            >
              <button
                className={`Transition-1`}
                style={{
                  position: "relative",
                  letterSpacing: "0.1rem",
                  backgroundColor: "var(--green-0)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.2rem",
                  gap: "0.6rem",
                }}
                onClick={() => {
                  document.getElementById("contato").scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <AlternateEmailIcon /> Contato
              </button>
              <button
                className={`Transition-1`}
                style={{
                  position: "relative",
                  letterSpacing: "0.1rem",
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  fontSize: "1.2rem",
                  gap: "0.6rem",
                }}
                onClick={() => {
                  document.getElementById("servicos").scrollIntoView({
                    behavior: "smooth",
                  });
                }}
              >
                <BusinessCenterIcon /> Serviços
              </button>
            </div>
          </ScrollTransition> */}
        </Box>
      </Container>
    </CustomSection>
  );
};

export default Landing;

import logo_white from "../../assets/static/logo/logo_deitado.png";
import logo_white_2 from "../../assets/static/logo/logo_no_text.svg";

import { useEffect, useState } from "react";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import styles from "./styles.module.css";
// import arrow_png from "../../assets/static/button/arrow.png";
import { Box } from "@mui/system";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";

import { useNavigate, useLocation, Link } from "react-router-dom";
import VerticalItems from "./VerticalItems";

const nav_links = [
  {
    name: "Home",
    link: "/",
    element_id: "#home",
  },
  {
    name: "Quem somos",
    link: "/",
    element_id: "#quem-somos",
  },
  {
    name: "Serviços",
    link: "/servicos",
    element_id: "#servicos",
  },
  {
    name: "Clientes",
    link: "/",
    element_id: "#clientes",
  },
];

const Navbar = ({ on_top, setOnTop, setForceOnTop }) => {
  const location = useLocation();
  const navigate = useNavigate();
  // responsive menu
  const [openMenu, setOpenMenu] = useState(false);

  const scrollTo = (element_id) => {
    console.log(location.pathname);

    if (location.pathname === "/") {
      const element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      // alert("codigo para redirecionar a home comentado para testes futuros");
      navigate("/", { replace: true });
      // after navigate to home, scroll to element
      setTimeout(() => {
        const element = document.querySelector(element_id);
        element.scrollIntoView({ behavior: "smooth" });
      }, 500);
    }
  };

  useEffect(() => {
    // scroll to top
    window.scrollTo(0, 0);
    if (location.pathname !== "/") {
      setOnTop(false);
      setForceOnTop(true);
    } else {
      setOnTop(true);
      setForceOnTop(false);
    }
  }, [location]);

  return (
    <nav
      // NavbarContainer = main styles
      // Transition = main transition
      // NavbarOnTop = styles when navbar is on top
      className={
        styles.NavbarContainer +
        " Transition-1 " +
        (on_top ? " " + styles.NavbarOnTop : "")
      }
      style={{
        backgroundColor: on_top ? "transparent" : "var(--green-0)",
        // boxShadow: "0 0 0 0.1rem rgba(255,255, 255, 0.2)",
        zIndex: 100,
        // boxShadow: on_top
        //   ? "none"
        //   : "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px, rgba(0, 0, 0, 0.1) 0px 7px 29px 0px",
      }}
    >
      <VerticalItems
        {...{
          openMenu,
          setOpenMenu,
          nav_links,
          scrollTo,
          styles,
          logo: logo_white,
          navigate,
        }}
      />
      <div
        className="Transition-1"
        style={{
          maxWidth: on_top ? "100%" : "1536px",
          width: "100%",
          margin: "0 auto",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: on_top ? "0.4rem 2rem 0.4rem 2rem" : "0 2rem 0 2rem",
          marginBottom: "0",
          borderRadius: "5rem",
        }}
      >
        <a href="/" style={{display:'flex'}}>
          <img
            className="Transition-1"
            src={logo_white}
            alt="Logotipo da Bluee Open"
            style={{
              height: on_top ? "4rem" : "3rem",
              // filter: on_top
              //   ? "brightness(var(--image-brightness))"
              //   : "inherit",
            }}
          />
           {/* <img
            className="Transition-1 d-sm-block"
            src={logo_white_2}
            alt="Logotipo da Bluee Open"
            style={{
              height: on_top ? "4rem" : "3rem",
              // filter: on_top
              //   ? "brightness(var(--image-brightness))"
              //   : "inherit",
            }}
          /> */}
        </a>

        {/* main items */}
        <Box
          className="Transition-1"
          sx={{
            display: "flex",
            position: "relative",
            display: { xs: "none", md: "flex" },
            gap: on_top
              ? {
                  md: "1.5rem",
                  lg: "3rem",
                }
              : {
                  md: "1rem",
                  lg: "2rem",
                },
            // top: "0",
          }}
        >
          {nav_links.map((item, index) => (
            <div className={styles.navItemsWrapper} key={index}>
              <Box
                className="Transition-1"
                // component="a"
                // href={item.link}
                onClick={() => scrollTo(item.element_id)}
                sx={{
                  cursor: "pointer",
                  color: on_top
                    ? "var(--text-color-0)"
                    : "var(--text-color-reverse-0)",
                  // fontWeight: on_top ? "bold" : "normal",
                  fontWeight: "bold",
                  padding: "0.4rem",
                  "&:after": {
                    content: '""',
                    position: "absolute",
                    width: "100%",
                    height: "3px",
                    bottom: "0",
                    left: "0",
                    backgroundColor: on_top
                      ? "var(--text-color-0)"
                      : "var(--text-color-reverse-0)",
                    transform: "scaleX(0)",
                    transformOrigin: "bottom right",
                    transition: "transform 0.3s",
                  },
                  "&:hover:after": {
                    transform: "scaleX(1)",
                    transformOrigin: "bottom left",
                  },
                }}
              >
                {item.name}
              </Box>
            </div>
          ))}
        </Box>

        <div
          className="Transition-1"
          style={{
            width: on_top ? "10rem" : "8rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "1rem",
          }}
        >
          <button
            className={`Transition-1`}
            style={{
              position: "relative",
              letterSpacing: "0.1rem",
              backgroundColor: on_top ? "var(--green-1)" : "white",
              color: on_top ? "white" : "var(--green-0)",
              fontSize: "1.1rem",
            }}
            onClick={() => scrollTo("#contato")}
          >
            Contato
          </button>

          <MenuRoundedIcon
            onClick={() => setOpenMenu(true)}
            sx={{
              display: { xs: "block", md: "none" },
              fontSize: "2.5rem",
              color: on_top
                ? "var(--text-color-0)"
                : "var(--text-color-reverse-0)",
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

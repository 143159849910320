import { Box, Typography } from "@mui/material";
import { Container } from "@mui/system";
import CustomSection from "components/CustomSection";
import swal from "sweetalert";
import form_styles from "components/SpecificStyles/form.module.css";
import { useState } from "react";
import { post } from "utilities/requests";
import CustomInput from "components/CustomInput";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import contact_us from "assets/static/landing/contact_us.svg";

const Contato = ({ invert_bg_theme = false, section_id }) => {
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");

  const phoneMaskBrazil = (e) => {
    if (e.target.value.length > 15) return;

    let element = e.target;
    let isAllowed = /\d|Backspace|Tab/;
    if (!isAllowed.test(e.nativeEvent.data)) e.preventDefault();

    let inputValue = element.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.replace(/(^\d{2})(\d)/, "($1) $2");
    inputValue = inputValue.replace(/(\d{4,5})(\d{4}$)/, "$1-$2");

    setPhone(inputValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (name === "") {
      swal("Erro!", "O campo nome é obrigatório!", "error");
      return;
    } else if (email === "") {
      swal("Erro!", "O campo e-mail é obrigatório!", "error");
      return;
    } else if (phone === "") {
      swal("Erro!", "O campo telefone é obrigatório!", "error");
      return;
    } else if (phone.length < 14) {
      swal("Erro!", "O campo telefone está inválido!", "error");
      return;
    } else if (message === "") {
      swal(
        "Erro!",
        "O campo mensagem está vazio... Por favor insira o motivo do contato.",
        "error"
      );
      return;
    } else if (subject === "") {
      swal(
        "Erro!",
        "O campo assunto está vazio... Por favor selecione o assunto do contato.",
        "error"
      );
      return;
    }
    let texto = encodeURI(`*Nome:* ${name}\n*E-mail:* ${email}\n*Telefone:* ${phone}\n*Assunto:* ${subject}\n*Mensagem:* ${message}`);

    let url = `https://wa.me/5517992781202?text=${texto}`
    document.getElementById('url').href=url;
    document.getElementById('url').click();

    // if (loading) return;

    // setLoading(true);

    // let data = new FormData();

    // data.append("name", name);
    // data.append("email", email);
    // data.append("phone", phone);
    // data.append("message", message);
    // data.append("subject", subject);

    // let response = await post("contact-us/add", data);

    // if (response.status) {
    //   setName("");
    //   setEmail("");
    //   setPhone("");
    //   setMessage("");

    //   swal(
    //     "Sucesso!",
    //     "Sua mensagem foi enviada com sucesso! Em breve entraremos em contato.",
    //     "success"
    //   );
    // } else {
    //   swal(
    //     "Erro!",
    //     "Ocorreu um erro ao enviar sua mensagem, por favor entre em contato com o suporte.",
    //     "error"
    //   );
    // }

    // setLoading(false);
  };

  return (
    <CustomSection
      style={{
        overflow: "hidden",
      }}
      id={"contato"}
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "column",
              lg: "row",
            },
            boxShadow: "rgba(17, 12, 46, 0.2) 0px 48px 100px 0px",
            borderRadius: "1rem",
            overflow: "hidden",
          }}
        >
          <div
            style={{
              backgroundColor: "var(--background-0)",
              border: "0.3rem solid var(--background-1)",
              padding: "2rem 3rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h3"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Entrar em contato
            </Typography>

            <Typography
              variant="p"
              component="p"
              sx={{
                // color: "var(--text-color-reverse-1)",
                color: "var(--text-color-1)",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "normal",
                fontSize: "1.3rem",
                maxWidth: "30rem",
              }}
            >
              Entre em contato para te ajudarmos!
            </Typography>
            <img
              src={contact_us}
              alt="Contato"
              style={{
                marginTop: "2rem",
                width: "100%",
                maxWidth: "20rem",
                objectFit: "contain",
              }}
            />
          </div>
          <div
            style={{
              backgroundColor: "var(--background-1)",
              padding: "2rem 3rem",
            }}
          >
            <form
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "2rem",
                width: "100%",
                maxWidth: "36rem",
              }}
              // className={
              //   invert_bg_theme
              //     ? form_styles.customFormStyleInverted
              //     : form_styles.customFormStyle
              // }
              className={`${form_styles.customFormStyle} ${
                invert_bg_theme && form_styles.invertedBgTheme
              }`}
              onSubmit={handleSubmit}
            >
              <CustomInput
                placeholder="Nome completo"
                icon={PersonIcon}
                value={name}
                onChange={setName}
                helperText="Insira seu nome civil ou social."
                required
              />
              <CustomInput
                type="email"
                placeholder="E-mail"
                icon={EmailIcon}
                value={email}
                onChange={setEmail}
                helperText="Insira seu e-mail para que possamos entrar em contato."
                required
              />
              <CustomInput
                placeholder="Telefone"
                icon={LocalPhoneIcon}
                value={phone}
                onChangeTarget={phoneMaskBrazil}
                helperText="Insira seu telefone para que possamos entrar em contato."
                required
              />

              <select
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              >
                <option value="">Selecione o assunto do contato</option>
                <option value="CETESB - Licenciamento Ambiental">CETESB - Licenciamento Ambiental</option>
                <option value="CETESB - Alteração de Documentos">CETESB - Alteração de Documentos</option>
                <option value="CETESB - Outros">CETESB - Outros</option>
                <option value="Ibama - CTF">Ibama - CTF</option>
                <option value="Ibama - RAPP">Ibama - RAPP</option>
                <option value="Ibama - Outros">Ibama - Outros</option>
                <option value="Polícia Federal - Mapa Mensal">Polícia Federal - Mapa Mensal</option>
                <option value="Polícia Federal - Outros">Polícia Federal - Outros</option>
              </select>

              <textarea
                placeholder="Mensagem"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />

              <input type="submit" />
            </form>

            <p
            style={{whiteSpace:'pre-line',textAlign:'center',marginTop:10}}
            >
              Não se preocupe! 
              <br/>
              Seus dados não serão compartilhados com
              terceiros.
            </p>
          </div>
          <a hidden id="url" href={'https://wa.me/5517992781202?text=teste'}></a>
        </Box>
      </Container>
    </CustomSection>
  );
};

export default Contato;

import CustomSection from "components/CustomSection";
import { Container, Box, Typography } from "@mui/material";
import SwiperCarousel from "./Swiper";
import ScrollTransition from "components/ScrollTransition";

const QuemSomos = () => {
  return (
    <CustomSection
      style={{
        overflow: "hidden",
        minHeight: "auto",
      }}
      bg_color="var(--background-1)"
      id="quem-somos"
    >
      <Container
        maxWidth="xl"
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
            lg: "row",
          },
          paddingY: {
            xs: "5rem",
            sm: "5rem",
            md: "10rem",
            lg: "10rem",
          },
          alignItems: "center",
          gap: "3vw",
        }}
      >
        <Box
          style={{
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
            },
            display: "flex",
            flexDirection: "column",
            gap: "3vh",
            borderLeft: "0.2rem solid var(--green-0)",
            paddingLeft: "2vw",
            paddingBottom: "0.8rem",
            borderRadius: "0.5rem",
          }}
        >
          <ScrollTransition>
            <h1
              style={{
                fontSize: "4rem",
                color: "var(--green-0)",
              }}
            >
              Quem somos
            </h1>
          </ScrollTransition>
          <ScrollTransition>
            <h2 style={{ color: "var(--text-color-1)" }}>
            Nosso time apresenta vasto conhecimento na área ambiental, realizando licenciamentos desde 2020.
            </h2>
          </ScrollTransition>
          <ScrollTransition>
            <p
              style={{
                color: "var(--text-color-0)",
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Além do licenciamento ambiental, também prestamos consultoria ambiental e atuamos também com órgãos regulamentadores como IBAMA e Polícia Federal.
            </p>
          </ScrollTransition>
          {/* <ScrollTransition>
            <p
              style={{
                color: "var(--text-color-0)",
                fontSize: "1.2rem",
                textAlign: "justify",
              }}
            >
              Sugar plum chocolate cake shortbread pie cheesecake cake I love
              pastry chocolate. I love topping cheesecake chocolate.
            </p>
          </ScrollTransition> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            width: {
              xs: "100%",
              sm: "100%",
              md: "50%",
              lg: "50%",
            },
          }}
        >
          <SwiperCarousel />
        </Box>
      </Container>
    </CustomSection>
  );
};

export default QuemSomos;

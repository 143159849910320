import { Container, Typography } from "@mui/material";
import CustomSection from "components/CustomSection";
import { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useInView } from "react-intersection-observer";

import customer_1 from "assets/static/customers/1_1.png";
import customer_2 from "assets/static/customers/2.png";
// import customer_3 from "assets/static/customers/3.jpg";
// import customer_4 from "assets/static/customers/4.jpg";
// import customer_5 from "assets/static/customers/5.jpg";
// import customer_6 from "assets/static/customers/6.jpg";
import ScrollTransition from "components/ScrollTransition";

const responsive = {
  0: {
    items: 2,
  },
  630: {
    items: 3,
  },
  830: {
    items: 4,
  },
  1000: {
    items: 5,
  },
  1230: {
    items: 6,
  },
};

const Clientes = () => {
  const [testimonies, setTestimonies] = useState([
    {
      name: "Consectetur Adipiscing",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/200/300",
      occupation: "CEO do Gogle",
    },
    {
      name: "Tincidunt Lacinia",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/300/300",
      occupation: "Diretor da Eipou",
    },
    {
      name: "Aliquam Nisl",
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl. Sed euismod, nunc vel tincidunt lacinia, nunc nisl aliquam nisl, eu aliquam nisl nisl sit amet nisl.",
      avatar: "https://picsum.photos/200/200",
      occupation: "Fundador da Microso Soft",
    },
  ]);

  const [readyToRender, setReadyToRender] = useState(true);

  // const getto = async () => {
  //   const res = await get(`${URL}api/testimonies`);

  //   if (res.httpCode === 200) {
  //     // setTestimonies(res.testimonies);
  //     let temp_testimonies = res.testimonies.map((data) => {
  //       return {
  //         name: data.name,
  //         text: data.text,
  //         avatar: STORAGE_URL+data.avatar_path,
  //         occupation: data.occupation,
  //       };
  //     });
  //     setTestimonies(temp_testimonies);
  //     setReadyToRender(true);
  //   }
  // };

  // useEffect(() => {
  //   getto().catch((e) => {
  //     console.log(e);
  //   });
  // }, []);

  return (
    readyToRender &&
    testimonies.length > 0 && (
      <CustomSection
        bg_color="var(--background-1)"
        style={{
          overflow: "hidden",
          position: "relative",
          minHeight: "auto",
        }}
        id="clientes"
      >
        <Container
          maxWidth="xl"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.8rem",
            margin: "auto",
            padding: "8rem 0",
            width: "100vw",
          }}
        >
          <ScrollTransition>
            <Typography
              variant="h2"
              sx={{
                color: "var(--green-0)",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              Nossos clientes
            </Typography>
          </ScrollTransition>

          <ScrollTransition
            sx={{
              margin: "0 auto",
              marginBottom: "2rem",
            }}
          >
            <Typography
              variant="p"
              component="p"
              sx={{
                // color: "var(--text-color-reverse-1)",
                color: "var(--text-color-1)",
                textAlign: "center",
                margin: "0 auto",
                fontWeight: "normal",
                fontSize: "1.3rem",
                maxWidth: "30rem",
                "&:after": {
                  content: "''",
                  display: "block",
                  marginTop: "30px",
                  width: "40px",
                  borderTop: "2px solid #444",
                  margin: "30px auto 0",
                },
              }}
            >
              Clientes já licenciados por nós
            </Typography>
          </ScrollTransition>

          <ScrollTransition>
            <OwlCarousel
              className="owl-theme"
              loop
              dots={false}
              nav={false}
              items={5}
              autoplay={true}
              autoplayTimeout={2000}
              responsive={responsive}
            >
              <div className="item" >
                <img style={{ height: "6rem",paddingRight:10 }} src={customer_1} />
              </div>
              <div className="item" >
                <img style={{ height: "6rem",paddingRight:10 }} src={customer_2} />
              </div>
              {/* <div className="item">
                <img style={{ height: "6rem" }} src={customer_2} />
              </div>
              <div className="item">
                <img style={{ height: "6rem" }} src={customer_3} />
              </div>
              <div className="item">
                <img style={{ height: "6rem" }} src={customer_4} />
              </div>
              <div className="item">
                <img style={{ height: "6rem" }} src={customer_5} />
              </div>
              <div className="item">
                <img style={{ height: "6rem" }} src={customer_6} />
              </div> */}
            </OwlCarousel>
          </ScrollTransition>
        </Container>
      </CustomSection>
    )
  );
};

export default Clientes;

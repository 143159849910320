import React from "react";
import { Main } from "./routes/Main";

function App() {
  return (
    <div className="App white-mode">
      <Main />
    </div>
  );
}

export default App;

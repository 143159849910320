import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/navigation";

import img1 from "assets/static/landing/carousel_img1.jpg";
import img2 from "assets/static/landing/carousel_img2.jpg";
import img3 from "assets/static/landing/carousel_img3.jpg";
import img4 from "assets/static/landing/carousel_img4.jpg";
import img5 from "assets/static/landing/carousel_img5.jpg";

const SwiperCarousel = ({
  forced_style = null,
  slide_speed = 1500,
  slide_delay = 5000,
}) => {
  const img_style = {
    width: "100%",
    height: "30rem",
    objectFit: "cover",
  };

  return (
    <>
      <Swiper
        style={{
          width: "100%",
          height: "100%",
          borderRadius: "4rem 4rem 4rem 0",
          boxShadow: "rgba(17, 12, 46, 0.2) 0px 48px 100px 0px",
          border: "5px solid var(--blue-1)",
          outline: "5px solid rgb(var(--accent-color0), 0.5)",
          ...forced_style,
        }}
        modules={[EffectFade, Autoplay]}
        // navigation={true}
        autoplay={{
          delay: slide_delay,
        }}
        speed={slide_speed}
        slidesPerView={1}
        effect={"fade"}
        fadeEffect={{ crossFade: true }}
      >
        <SwiperSlide>
          <img style={img_style} src={img1} alt="Imagem do Carousel" />
        </SwiperSlide>
        <SwiperSlide>
          <img style={img_style} src={img2} alt="Imagem do Carousel" />
        </SwiperSlide>
        <SwiperSlide>
          <img style={img_style} src={img3} alt="Imagem do Carousel" />
        </SwiperSlide>
        <SwiperSlide>
          <img style={img_style} src={img4} alt="Imagem do Carousel" />
        </SwiperSlide>
        <SwiperSlide>
          <img style={img_style} src={img5} alt="Imagem do Carousel" />
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default SwiperCarousel;

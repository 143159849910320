import { Box, Container } from "@mui/material";
import logo from "../../assets/static/logo/logo_pe.png";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import { useNavigate, useLocation } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";

const footer_links = [
  {
    name: "Home",
    link: "/",
    element_id: "#home",
  },
  {
    name: "Quem somos",
    link: "/",
    element_id: "#quem-somos",
  },
  {
    name: "Serviços",
    link: "/servicos",
    element_id: "#servicos",
  },
  {
    name: "Clientes",
    link: "/",
    element_id: "#clientes",
  },
];

const social_medias_button_style = {
  fontSize: "2.1rem",
  opacity: "0.6",
  cursor: "pointer",
  color: "white",
  "&:hover": { opacity: 1 },
};

const social_medias = [
  {
    link: "https://www.instagram.com/amare_ambiental?igsh=aTRsYWtidXBrZjBn",
    icon: <InstagramIcon sx={social_medias_button_style} />,
  },
  {
    link: "https://wa.me/5517992781202?text=Olá,%20Gostaria%20de%20fazer%20um%20licenciamento",
    icon: <WhatsApp sx={social_medias_button_style} />,
  },
  // {
  //   link: "/",
  //   icon: <FacebookIcon sx={social_medias_button_style} />,
  // },
  // {
  //   link: "/",
  //   icon: <LinkedInIcon sx={social_medias_button_style} />,
  // },
];

const Footer = () => {
  const location = useLocation();
  // const navigate = useNavigate();

  const scrollTo = (element_id) => {
    console.log(location.pathname);
    if ((location.pathname = "/")) {
      const element = document.querySelector(element_id);
      element.scrollIntoView({ behavior: "smooth" });
    } else {
      alert("codigo para redirecionar a home comentado para testes futuros");
      // navigate('/', { replace: true });
    }
  };

  return (
    <footer style={{ backgroundColor: "var(--green-0)", padding: "4rem 0" }}>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <span
          style={{
            position: "absolute",
            top: "110%",
            left: "50%",
            transform: "translateX(-50%)",
            color: "var(--text-color-reverse-0)",
            fontSize: "0.8rem",
          }}
        >
          Copyrights © {new Date().getFullYear()} All Rights Reserved by Amare
          Ambiental Inc.
        </span>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
            height: { md: "10rem", xs: "auto" },
            gap: "0.6rem 1rem",
            // transform: { md: "translateX(60%)", xs: "auto" },
          }}
        >
          {footer_links.map((item, index) => (
            <Box
              key={index}
              component="a"
              // href={item.link}
              sx={{
                color: "white",
                padding: "0.2rem 0",
                cursor: "pointer",
                "&:hover": { filter: "brightness(0.7)" },
              }}
              onClick={() => scrollTo(item.element_id)}
            >
              {item.name}
            </Box>
          ))}
        </Box>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <a href="/">
            <img
              style={{ height: "9rem" }}
              src={logo}
              alt="Logotipo da Bluee Open"
            />
          </a>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            {social_medias.map((social_media, index) => (
              <a style={{marginRight:index==1?0: 10}} key={index} href={social_media.link} target={"_blank"}>
                {social_media.icon}
              </a>
            ))}
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default Footer;
